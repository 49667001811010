/// <reference types="vite/client" />
import { ref, watch, onMounted, nextTick } from "vue";
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import axios from "axios";

// ✅ Initialize Algolia Client
const appId = import.meta.env.VITE_ALGOLIA_APPLICATION_ID;
const apiKey = import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY;
console.log('=====   T E S T I N G   | meta : ' + appId + ' |   T E S T I N G   =====');
const searchClient = algoliasearch(appId, apiKey);
const isLoading = ref(false); // ✅ Define isLoading here
export function AlgoliaSearchComponent(RESULTS_PER_PAGE: number = 12) {
    const query = ref<string>("");
    const selectedTopic = ref<string>("");
    const selectedResourceType = ref<string>("");
    const results = ref<any[]>([]);
    const autocompleteResults = ref<any[]>([]);
    const total = ref<number>(0);
    const totalPages = ref<number>(0);
    const page = ref<number>(1); // ✅ 1-based pagination
    const showAutocomplete = ref<boolean>(false);
    const searchTopics = ref<any[]>([]);
    const searchResourceTypes = ref<any[]>([]);
    const isSearchPage = ref<boolean>(window.location.pathname.includes("/search"));

    const indexes = [
        "ARTICLES",
        "ASSETS",
        "BLOG",
        "COURSES",
        "GLOSSARY",
        "MODULES",
        "QUESTIONS_ANSWERS",
        "SKILL_EXPLAINER",
    ].map((index) => import.meta.env.VITE_ALGOLIA_INDEX_PREFIX + index);

    // ✅ Populate fields from URL params
    const populateFromURL = () => {
        if (!isSearchPage.value) return;
        const params = new URLSearchParams(window.location.search);
        query.value = params.get("q") || "";
        selectedTopic.value = params.get("topic") || "";
        selectedResourceType.value = params.get("resourceType") || "";
        page.value = parseInt(params.get("page") || "1");
    };

    const updateURL = (params) => {
        if (!isSearchPage.value) return;

        let queryString = [];

        queryString.push(`q=${encodeURIComponent(params.q || "")}`); // ✅ Always include `q`
        queryString.push(`topic=${encodeURIComponent(params.topic || "")}`); // ✅ Keep empty `topic`
        queryString.push(`resourceType=${encodeURIComponent(params.resourceType || "")}`); // ✅ Keep empty `resourceType`

        // ✅ Page should always be included
        queryString.push(`page=${params.page}`);

        const newUrl = `${window.location.pathname}?${queryString.join("&")}`;

        // ✅ Only update the URL if something changed
        if (window.location.search !== `?${queryString.join("&")}`) {
            window.history.replaceState({}, "", newUrl);
        }
    };


    // ✅ Fetch full search results
    const fetchSearchResults = async () => {
        if (!query.value.trim() && !selectedTopic.value && !selectedResourceType.value) {
            results.value = [];
            total.value = 0;
            totalPages.value = 0;
            console.warn('⚠️ No search query or filters provided.');
            return;
        }

        try {
            let facetFilters: string[][] = [];

            // ✅ Enforce AND logic by grouping facets together
            if (selectedTopic.value && selectedResourceType.value) {
                facetFilters.push([`relatedTopics:${selectedTopic.value}`], [`relatedResourceTypes:${selectedResourceType.value}`]);
            } else {
                if (selectedTopic.value) facetFilters.push([`relatedTopics:${selectedTopic.value}`]);
                if (selectedResourceType.value) facetFilters.push([`relatedResourceTypes:${selectedResourceType.value}`]);
            }

            console.log('🔍 Sending Algolia Query:', {
                query: query.value || "",
                facetFilters,
                indexes
            });

            const response = await searchClient.search(
                indexes.map(index => ({
                    indexName: index,
                    query: query.value || "",
                    params: {
                        hitsPerPage: 1000, // ✅ Fetch everything at once for full dataset slicing
                        facetFilters: facetFilters.length ? facetFilters : undefined,
                    }
                }))
            );

            console.log('✅ Algolia Response:', response.results);

            // ✅ Flatten all results from all indexes once
            let allResults = response.results.flatMap(result => result.hits);

            // ✅ Sort by relevance (if ranking info is available)
            allResults.sort((a, b) => (b._rankingInfo?.nbExactWords || 0) - (a._rankingInfo?.nbExactWords || 0));

            // ✅ Set global total
            total.value = allResults.length;

            // ✅ Handle global pagination using slicing
            totalPages.value = Math.ceil(total.value / RESULTS_PER_PAGE);
            results.value = allResults.slice((page.value - 1) * RESULTS_PER_PAGE, page.value * RESULTS_PER_PAGE);

            updateURL({ q: query.value, topic: selectedTopic.value, resourceType: selectedResourceType.value, page: page.value });
        } catch (error) {
            console.error('🚨 Error fetching search results:', error);
        }
    };



    let lastQuery = ""; // ✅ Store the last query to prevent redundant API calls

    async function fetchAutocompleteResults() {
        try {
            if (!query.value || query.value.trim() === '') {
                showAutocomplete.value = false; // 🔄 Hide autocomplete immediately
                autocompleteResults.value = []; // Clear results
                return;
            }

            if (query.value.length < 3) return;

            if (query.value === lastQuery) return;
            lastQuery = query.value;

            console.log("🔄 Fetching autocomplete from Algolia for:", query.value);

            const response = await searchClient.search(
                indexes.map((index) => ({
                    indexName: index,
                    query: query.value,
                    params: {
                        hitsPerPage: 5,
                        attributesToRetrieve: ['title', 'url', 'teaser', 'relatedTopics', 'relatedResourceTypes'],
                    },
                }))
            );

            const hits = response.results[0]?.hits || [];
            console.log("✅ Algolia API Response:", hits);

            autocompleteResults.value = [...hits]; // Ensure Vue detects changes
            await nextTick();

            // ✅ Hide autocomplete if results are empty
            showAutocomplete.value = autocompleteResults.value.length > 0;
        } catch (error) {
            console.error("❌ Error fetching autocomplete:", error);
        }
    }




    // ✅ Fetch available facets
    const fetchFacets = async () => {
        try {
            const response = await axios.get("/actions/readinguniverse/algolia/get-facets-from-craft");
            searchTopics.value = response.data.topicsFacets || [];
            searchResourceTypes.value = response.data.resourceFacets || [];
        } catch (error) {
            console.error("🚨 Error fetching facets:", error);
            searchTopics.value = [];
            searchResourceTypes.value = [];
        }
    };


    // ✅ Initialize on component mount
    onMounted(() => {
        populateFromURL();
        fetchFacets();
    });

    return {
        query,
        selectedTopic,
        selectedResourceType,
        results,
        autocompleteResults,
        total,
        totalPages,
        page,
        fetchSearchResults,
        fetchAutocompleteResults,
        fetchFacets,
        showAutocomplete,
        searchTopics,
        searchResourceTypes,
    };
}
export { isLoading };
