<template>
    <nav class="container py-6 md:py-12">
        <div class="flex flex-wrap justify-center items-center">
            <!-- Pages List -->
            <div class="inline-flex justify-center items-center gap-3">
                <!-- Previous Button -->
                <div :class="pageSectionClasses">
                    <button
                        :disabled="!hasPreviousPage"
                        :class="'bg-white p-4 rounded-full' + (hasPreviousPage ? ' hover:bg-gray-1' : '')"
                        @click="updatePage(previousPage)"
                        type="button"
                        :data-page="previousPage"
                    >
                        <icon name="angle-left" :class="(hasPreviousPage ? 'fill-blue-default' : 'fill-blue-light-2') + ' h-6 w-6'"/>
                    </button>
                </div>

                <!-- First Page -->
                <div v-if="currentPage !== 1" :class="pageSectionClasses">
                    <button
                        class="rounded-full py-2 px-3 md:py-4 md:px-6 text-blue-default font-bold hover:bg-gray-1"
                        @click="updatePage(1)"
                        type="button"
                        data-page="1"
                    >
                        1
                    </button>
                </div>

                <!-- Show ellipsis before current page only if there's a gap -->
                <div v-if="previousPages.length > 0 && previousPages[0] > 2" :class="pageSectionClasses">
                    <span class="py-2 px-3 md:py-4 md:px-6 text-blue-default">&hellip;</span>
                </div>

                <!-- Previous Pages -->
                <div v-for="page in previousPages" :key="page" :class="pageSectionClasses">
                    <button
                        class="text-blue-default py-2 px-3 md:py-4 md:px-6 rounded-full font-bold hover:bg-gray-1"
                        @click="updatePage(page)"
                        type="button"
                        :data-page="page"
                    >
                        {{ page }}
                    </button>
                </div>

                <!-- Current Page -->
                <div :class="pageSectionClasses">
                    <button
                        disabled
                        class="text-white bg-blue-default hover:font-bold py-2 px-3.5 rounded-full md:py-4 md:px-6"
                    >
                        {{ currentPage }}
                    </button>
                </div>

                <!-- Next Pages -->
                <div v-for="page in nextPages" :key="page" :class="pageSectionClasses">
                    <button
                        class="text-blue-default py-2 px-3 md:py-4 md:px-6 font-bold rounded-full hover:bg-gray-1"
                        @click="updatePage(page)"
                        type="button"
                        :data-page="page"
                    >
                        {{ page }}
                    </button>
                </div>

                <!-- Show ellipsis after current page only if there's a gap -->
                <div v-if="nextPages.length > 0 && nextPages[nextPages.length - 1] < props.totalPages - 1" :class="pageSectionClasses">
                    <span class="py-2 px-3 md:py-4 md:px-6 text-blue-default">&hellip;</span>
                </div>

                <!-- Last Page -->
                <div v-if="currentPage !== totalPages && totalPages !== 1">
                    <button
                        :class="[
                                    currentPage === totalPages
                                    ? 'text-white bg-blue-default hover:font-bold'
                                    : 'text-blue-default font-bold hover:bg-gray-1',
                                    'py-2 px-3 rounded-full md:py-4 md:px-6'
                                ]"
                        @click="updatePage(totalPages)"
                        type="button"
                        :data-page="totalPages"
                    >
                        {{ totalPages }}
                    </button>
                </div>


                <!-- Next Button -->
                <div :class="pageSectionClasses">
                    <button
                        :disabled="!hasNextPage"
                        :class="['bg-white p-4 rounded-full', hasNextPage ? 'hover:bg-gray-1' : '']"
                        @click="updatePage(nextPage)"
                        type="button"
                        :data-page="nextPage"
                    >
                        <icon name="angle-right" :class="(hasNextPage ? 'fill-blue-default' : 'fill-blue-light-2') + ' h-6 w-6'"/>
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
    import { computed } from 'vue';
    import Icon from './Icon.vue';

    const props = defineProps({
        currentPage: { type: Number, required: true, default: 1 },
        total: { type: Number, required: true, default: 1 },
        count: { type: Number, required: true, default: 1 },
        perPage: { type: Number, required: true, default: 1 },
        totalPages: { type: Number, required: true, default: 1 },
        previousLink: { type: String, required: false, default: '' },
        nextLink: { type: String, required: false, default: '' }
    });

    const emit = defineEmits(['paginate']);

    const neighbors = computed(() => 1);
    const hasNextPage = computed(() => props.currentPage < props.totalPages);
    const hasPreviousPage = computed(() => props.currentPage > 1);
    const nextPage = computed(() => (hasNextPage.value ? props.currentPage + 1 : 1));
    const previousPage = computed(() => (hasPreviousPage.value ? props.currentPage - 1 : 1));


    const previousPages = computed(() => {
        return Array.from({ length: neighbors.value }, (_, i) => props.currentPage - (i + 1))
            .filter(page => page > 1) // ✅ Prevents duplicate page 1
            .reverse();
    });

    const nextPages = computed(() => {
        return Array.from({ length: neighbors.value }, (_, i) => props.currentPage + (i + 1))
            .filter(page => page < props.totalPages); // ✅ Prevents duplicate last page
    });


    const countStart = computed(() => (hasPreviousPage.value ? props.perPage * (props.currentPage - 1) + 1 : 1));
    const countEnd = computed(() => Math.min(countStart.value + props.perPage - 1, props.total));

    const pageSectionClasses = computed(() => 'flex-initial inline-flex gap-3 justify-center items-center');

    const updatePage = (page) => {
        console.log(`📄 Pagination clicked: Going to page ${page}`);

        // Scroll to top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Ensures smooth scrolling
        });

        emit('paginate', parseInt(page));
    };

</script>
